

















































import Vue from "vue"
import { mapGetters } from "vuex"
import _, { get } from "lodash"

export default Vue.extend({
  name: "RatingPollResult",
  props: {
    data: Array
  },
  data() {
    return {
      max: 5
    }
  },
  computed: {
    ...mapGetters([
      "missions",
      "orgID",
      "gameID",
      "missionPlaysArray",
      "plays"
    ]),
    ...mapGetters("group", ["isViewerHostLike"]),
    ...mapGetters({
      currentMission: "getCurrentMission"
    }),
    linkedMissions() {
      const linkedMissions = this.currentMission?.linkedMissions || []

      const current = this.currentMission?.activityId
        ? Object.values(this.missions).find(
            mission =>
              // @ts-expect-error TODO:
              mission.activityId === this.currentMission?.activityId &&
              // @ts-expect-error TODO:
              mission.play
          )
        : this.currentMission

      return linkedMissions.reduce(
        (acc, missionID) => {
          const mission = get(this.missions, missionID, false)
          if (mission) {
            acc.splice(acc.length - 1, 0, mission)
          }
          return acc
        },
        [current]
      )
    },
    linkedMissionResults() {
      const missionsID = this.linkedMissions.map(({ id }) => id)

      const missionsPlays = this.plays.filter(({ missionID }) =>
        missionsID.includes(missionID)
      )

      return _.chain(missionsPlays)
        .groupBy("missionID")
        .reduce((acc, missionPlays, missionID) => {
          const sum = missionPlays.reduce(
            (a, play) => a + get(play, "answer[0]", 0),
            0
          )
          const avg = Math.round((sum / missionPlays.length) * 2) / 2 || 0

          acc[missionID] = avg

          return acc
        }, {})
        .value()
    },
    chartData() {
      return this.linkedMissions.map(({ id, notes, instructions }) => {
        const result = this.linkedMissionResults[id]
        return {
          title: result ? instructions : "no data",
          style: { height: `${(100 / this.max) * result}%` },
          ...(result && { result })
        }
      })
    },
    differences() {
      return this.chartData.reduce((acc, item, index, arr) => {
        if (index > 0) {
          const difference = Math.abs(item.result - arr[index - 1].result)
          acc.push({ difference: difference, points: Math.ceil(difference) })
        }
        return acc
      }, [])
    }
  }
})
